import debounce from "lodash.debounce";

export default function postsFilter() {
    $(document).on('input', '[data-filters] input', debounce(function () {
        $(this).closest('form').trigger('submit');
    }, 500));

    $(document).on('change', '[data-filters] select', function () {
        let form = $(this).closest('form');

        form.trigger('submit');

        form.find('select').each(function () {
            $(this).prop('disabled', true);
        });
    });

    $(document).on('submit', '[data-filters]', function (e) {
        e.preventDefault();

        let form = $(this);
        let formData = form.serializeArray();
        let postTypes = form.attr('data-post-types');
        let postIds = form.attr('data-post-ids');
        let targetName = form.attr('data-load-target');
        let wrapper = $(`[data-paginate-wrap="${targetName}"]`);

        if (!postTypes || !wrapper) {
            return;
        }

        if (targetName == 'reports') {
            wrapper.show().html('<p>Loading...</p>');
            $(`[data-reports="reports"]`).html('').siblings().show();
        }

        postTypes = JSON.parse(postTypes);
        postIds = postIds ? JSON.parse(postIds) : [];

        $.ajax({
            url: SlateAjax.ajax_admin_url,
            type: 'post',
            data: {
                action: 'czapp_filter',
                form: formData,
                postTypes: postTypes,
                postIds: postIds,
            },
            success: function (response) {
                if (response.success == true) {
                    setTimeout(function () {
                        wrapper.show();
                        wrapper.html(response.data.html);
                        $(`.pagination[data-paginate-target="${targetName}"]`).replaceWith(response.data.paginationHtml);
                    }, 500);
                }

                if (response.success == false) {
                    alert('Sorry, there was an error.');
                }
            },
            error: function () {
                alert('Sorry, there was an error.');
            },
            complete: function () {
                form.find('select').each(function () {
                    $(this).prop('disabled', false);
                });
            }
        });
    });
}
